@import "variables";
@import '~bootstrap/scss/bootstrap.scss';

svg {
  fill: currentColor;
}

// gradient tła kart

.card{
  text-align: left;
}
.card-header {
  background: linear-gradient(rgb(70, 0, 95), rgb(37, 0, 80));
  color: var(--white);
  padding: 1.8125rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  margin-top: -2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  small {
    font-weight: 300;
    font-size: 1.25rem;
  }

  svg {
    fill: $primary;
    width: 1.375rem;
    height: 1.375rem;
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
}

.custom-control-label {
  color: $component-active-bg;
  font-weight: 400;
}

.custom-checkbox {
  .custom-control-label::before {
    border-color: $component-active-bg;
    border-width: (2/16)+rem;
  }
}

// Form custom

.form-label{
    margin-bottom:0;
}

.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  padding: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: $primary!important;
  background-image: url('../assets/alert.svg')!important;
  background-position: center right!important;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: rgb(82, 16, 114)!important;
}

.invalid-feedback{
  color: $primary!important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-weight: 600;
  background-color: white!important;
  border: none;
  border-bottom: 1.5pt solid rgb(82, 16, 114);
  -webkit-text-fill-color: rgb(82, 16, 114);
  transition: background-color 5000s ease-in-out 0s;
}


///* Za https://www.kizu.ru/flexible-overflow/ */
//.overflower {
//  display: inline-block;
//  overflow: hidden;
//
//  box-sizing: border-box;
//  max-width: 100%;
//  height: 1.5em;
//  line-height: 1.5em;
//
//  white-space: nowrap;
//  text-overflow: ellipsis;
//}
//
//.overflower-long {
//  display: inline;
//}
//
//.overflower-short {
//  display: none;
//}
//
//@supports (flex-wrap: wrap) {
//  .overflower {
//    display: inline-flex;
//    flex-wrap: wrap;
//  }
//
//  .overflower-short {
//    display: block;
//    overflow: hidden;
//
//    flex-grow: 1;
//    width: 0;
//
//    text-overflow: ellipsis;
//  }
//
//  .overflower-long {
//    flex-basis: 100%;
//  }
//}