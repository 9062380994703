@import '../variables';

.card.register.step2 {
  .card-body {
    font-size: (14/16)+rem;
    line-height: (26/16)+rem;

    h3 {
      font-size: 1rem;
      color: $secondary;
      font-weight: 600;
    }

    button {
      margin-top: 1rem;
    }
  }

  .card-footer {
    background: #FFF;
    border-top: solid 1px $light;
    border-radius: 0 0 .25rem .25rem;
  }

  .regulamin {
    font-size: (14/16)+rem;
    height: 60vh;

    h2 {
      font-size: (19/16)+rem;
      color: $primary;
      font-weight: 400;
    }
  }

  .scrollbars {
    height: calc(100% - 4.5rem); // ~2.5 to nagłówek + 2rem pad od dołu
    p {
      text-align: justify !important;
    }
  }

}