$primary: rgb(251, 7, 104);
$secondary: rgb(82, 16, 114);
$body-bg: rgba(30,0,100,1);

// Kolory są przepisane z domyślnych BS żeby nie importować całego BS w modułach
$gray-100: #f8f9fa;
$gray-200: #e5e3e9;
$gray-300: #dee2e6; // default BS
$gray-400: #ced4da; // default BS
$gray-500: rgb(135, 130, 138); // default BS
$gray-600: rgb(117, 107, 111);
$gray-700: #495057; // default BS
$gray-800: #343a40; // default BS
$gray-900: #212529; // default BS

$light:         $gray-200;
$body-color: $gray-600;

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,700');

// czcionka
//$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif:      Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// buttony
$btn-border-width: 3px;
$btn-border-radius: 1.5rem;
$btn-font-weight: 700;
$btn-line-height: initial;
$btn-padding-x: 1rem;
$btn-padding-y: .5rem;
$btn-border-radius-sm: 1.5rem;
$btn-padding-x-sm: .65rem;
$btn-padding-y-sm: .25rem;

// konfiguracja funkcjonalna
$enable-print-styles: false;

// karty
$card-border-width: 0;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;

//formy
$form-grid-gutter-width: 2rem; // taki sam jak pady w kartach
$component-active-bg: $secondary;
$input-border-radius: 0;
$input-border-width: 1.5pt;
$input-focus-box-shadow: none;
$input-border-color: $secondary;
$input-focus-border-color: $primary;
$input-color: $gray-600; // to samo co $input-placeholder-color
$input-focus-color: $primary;
